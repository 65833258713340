import React from 'react';
import Layout from '../components/Layout';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import '../styles/ThanksPage.scss';

const ThanksPage = ({ data }) => {
	return (
		<Layout>
			<Navbar />
			<div className="ThanksPage">
				<div className="has-text-centered">
					<h1 className="is-size-2 has-text-weight-bold">Thank You</h1>
					<p>We have received your submission.</p>
					<p>
						<a href="/">back home</a>
					</p>
				</div>
			</div>
			<Footer />
		</Layout>
	);
};

export default ThanksPage;
